.App {
  margin: 0 0 40px 0;
  padding: 0;
}

.logo {
  text-align: center;
  padding: 10px 0;
  color: #fff;
}

.logo > img {
  max-height: 150px;
}

.main-body {
  margin-top: 40px;
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 320px)
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2) {

  .logo > img {
    max-height: 80px;
  }

}
