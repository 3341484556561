.error {
    color: #ffffff;
    font-size: 1.25em;
    padding: 8px 0;
}

.success {
    color: #ffffff;
    font-size: 1.5em;
}

.phone, .email {
    font-style: italic;
}
